/**
 * @generated SignedSource<<c4b31b972cc1d19d287f4abbea0b27ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProfileAccountType = "CREATOR" | "COLLECTOR" | "COLLECTION" | "ANON" | "DOWNGRADED_CREATOR";
export type StoreLinkLinkType = "DISCORD_USER" | "ARTSTATION" | "BEHANCE" | "FACEBOOK" | "INSTAGRAM" | "PINTEREST" | "STEEMIT" | "TWITTER" | "WEBSITE" | "YOUTUBE_CHANNEL" | "YOUTUBE_USER" | "WHATSAPP" | "TELEGRAM";
import { FragmentRefs } from "relay-runtime";
export type ProfilesFragment$data = {
  readonly aboutBanner: string | null;
  readonly accountType: ProfileAccountType;
  readonly address: string | null;
  readonly collectionTotalSupply: number | null;
  readonly curator: {
    readonly fullName: string | null;
    readonly id: string;
    readonly username: string;
  } | null;
  readonly defaultCategory: {
    readonly categorySlug: string;
    readonly id: string;
  } | null;
  readonly defaultCollection: {
    readonly categorySlug: string;
    readonly id: string;
  } | null;
  readonly description: string | null;
  readonly displayName: string | null;
  readonly drop: {
    readonly heroDescription: string;
    readonly slug: string | null;
  } | null;
  readonly etherscanUrl: string | null;
  readonly floorPrice: {
    readonly floorPriceInEth: number | null;
    readonly floorPriceInUsd: number | null;
  } | null;
  readonly fullName: string | null;
  readonly hasCollectedDigitalMedias: boolean | null;
  readonly hasLikes: boolean | null;
  readonly id: string;
  readonly isCurrentUser: boolean;
  readonly listingStats: {
    readonly floorPrice: {
      readonly floorPriceInEth: number | null;
      readonly floorPriceInUsd: number | null;
    } | null;
    readonly totalItems: number | null;
  } | null;
  readonly numOfItemsListed: number | null;
  readonly numOfOwners: number | null;
  readonly openseaUrl: string | null;
  readonly profileImageUrl: string | null;
  readonly shortDescription: string | null;
  readonly slug: string | null;
  readonly socialLinks: ReadonlyArray<{
    readonly id: string;
    readonly linkType: StoreLinkLinkType;
    readonly value: string;
  } | null> | null;
  readonly totalExhibitions: number | null;
  readonly totalItems: number | null;
  readonly totalVolume: {
    readonly totalVolumeInEth: number | null;
    readonly totalVolumeInUsd: number | null;
  } | null;
  readonly user: {
    readonly email: string;
    readonly fullName: string | null;
    readonly id: string;
    readonly pk: string;
    readonly profileImageUrl: string;
    readonly username: string;
  } | null;
  readonly " $fragmentType": "ProfilesFragment";
};
export type ProfilesFragment$key = {
  readonly " $data"?: ProfilesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfilesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "categorySlug",
    "storageKey": null
  },
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "FloorPriceNode",
  "kind": "LinkedField",
  "name": "floorPrice",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floorPriceInEth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floorPriceInUsd",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalItems",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfilesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aboutBanner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "collectionTotalSupply",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserType",
      "kind": "LinkedField",
      "name": "curator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreCategoryType",
      "kind": "LinkedField",
      "name": "defaultCategory",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreCategoryType",
      "kind": "LinkedField",
      "name": "defaultCollection",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DropFamilyTypeV2",
      "kind": "LinkedField",
      "name": "drop",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "heroDescription",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "etherscanUrl",
      "storageKey": null
    },
    (v5/*: any*/),
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCollectedDigitalMedias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasLikes",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCurrentUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ListingStatsType",
      "kind": "LinkedField",
      "name": "listingStats",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOfItemsListed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOfOwners",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openseaUrl",
      "storageKey": null
    },
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreLinkType",
      "kind": "LinkedField",
      "name": "socialLinks",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalExhibitions",
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalVolumeNode",
      "kind": "LinkedField",
      "name": "totalVolume",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalVolumeInEth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalVolumeInUsd",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserType",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pk",
          "storageKey": null
        },
        (v7/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ProfileType",
  "abstractKey": null
};
})();

(node as any).hash = "6d25bb6565be2c355136ac5a504c785f";

export default node;
