import {
  ChangeEvent,
  PureComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useMutation, usePreloadedQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import {
  MPActionButton,
  MPColorClass,
  MPFonts,
  MPInlineTextLinkButton,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { joinClasses, useOnEnterKey } from '@mp-frontend/core-utils';

import NFTlogViewMutationType, {
  NFTlogViewMutation,
} from 'graphql/__generated__/NFTlogViewMutation.graphql';
import NFTSType, { NFTsQuery } from 'graphql/__generated__/NFTsQuery.graphql';
import {
  ContractNameEnum,
  GraphPageViewObjectType,
  MpErrors,
} from 'types/__generated__/graphql';

import { APP_NAME } from 'constants/Utils';
import MPGraphQLError from 'errors/MPGraphQLError';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { NFTType } from 'types/graphql/NFT';
import { LOCAL_STORAGE_KEYS } from 'utils/localStorageUtils';

import ProductContainer from './ProductContainer';
import ProductLayoutContainer from './ProductLayoutContainer';
import { CuratorDialog } from './ProductPrivateCurator';

import * as styles from 'css/pages/product/ProductPage.module.css';

export function useGetNFTFromPreloadedQuery(queryRef) {
  const { nfts } = usePreloadedQuery<NFTsQuery>(NFTSType, queryRef);
  const nft = nfts.edges.length > 0 ? nfts.edges[0].node : null;
  return nft;
}

function ProductPage({ password = undefined }: { password?: string }) {
  const { nftSlug } = useParams();
  const [pageViewLogged, setPageViewLogged] = useState<boolean>(false);
  const [nftMetadata, setMetadata] = useState<NFTType['metadata']>(null);
  // This fetches minted and non minted token incase not live product.

  const [nftViewMutation] = useMutation<NFTlogViewMutation>(
    NFTlogViewMutationType
  );

  const nftPageView = useCallback(() => {
    if (nftMetadata && !pageViewLogged) {
      setPageViewLogged(true);
      nftViewMutation({
        variables: {
          objectId: parseInt(nftMetadata.pk, 10),
          objectType: GraphPageViewObjectType.Nft,
        },
      });
    }
  }, [nftMetadata, pageViewLogged, nftViewMutation]);

  useEffect(() => {
    nftPageView();
  }, [nftPageView]);

  return (
    <ProductLayoutContainer id="productPage">
      <ProductContainer
        approvalRegistryContractQuery={{
          variables: { name: ContractNameEnum.ApprovedCreatorRegistry },
        }}
        nftsQuery={{
          variables: { first: 1, password, productSlug: nftSlug },
        }}
        transferCoreContractQuery={{
          variables: { name: ContractNameEnum.TransferCore },
        }}
        password={password}
        setMetadata={setMetadata}
      />
    </ProductLayoutContainer>
  );
}

const generateSessionStorageKey = (productPath: string) =>
  `${LOCAL_STORAGE_KEYS.PRODUCT_PAGE_PASSWORD}-${productPath}`;

function PasswordPage({
  curatorFullName,
  curatorUsername,
  password,
  onSubmit,
}: {
  onSubmit: (password: string) => void;
  password: string;
  curatorFullName?: string;
  curatorUsername?: string;
}) {
  const [passwordValue, setPassword] = useState<string>(password);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPassword(event.currentTarget.value);
    },
    [setPassword]
  );

  const handleSubmit = useCallback(
    () => onSubmit(passwordValue),
    [passwordValue, onSubmit]
  );

  const handleKeyPress = useOnEnterKey(handleSubmit);

  return (
    <div
      className={joinClasses(styles.protectedPage, CSSGlobal.Flex.CenteredCol)}
    >
      <div
        className={joinClasses(
          MPColorClass.CommonBlack,
          CSSGlobal.Cursor.Default,
          CSSGlobal.Flex.Col,
          CSSGap[16],
          styles.protectedForm
        )}
      >
        <div
          className={joinClasses(
            CSSGlobal.TextAlign.Centered,
            MPFonts.headline4
          )}
        >
          Welcome to Your Private Viewing Room
        </div>
        <div
          className={joinClasses(
            CSSGlobal.TextAlign.Centered,
            MPFonts.paragraphSmall
          )}
        >
          Please enter the code sent by the {APP_NAME} Concierge team.
        </div>
        <MPStyledTextField
          placeholder="Enter your code"
          value={passwordValue}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          type="password"
        />
        <MPActionButton disabled={!passwordValue} onClick={handleSubmit}>
          Continue
        </MPActionButton>
        <div
          className={joinClasses(
            CSSGlobal.Flex.InlineRow,
            CSSGlobal.Flex.JustifyCenter,
            MPColorClass.SolidNeutralGray5
          )}
        >
          Didn&apos;t receive a code?&nbsp;
          {curatorFullName && curatorUsername ? (
            <CuratorDialog
              fullName={curatorFullName}
              username={curatorUsername}
            >
              {({ open }) => (
                <MPInlineTextLinkButton onClick={open}>
                  Get in touch
                </MPInlineTextLinkButton>
              )}
            </CuratorDialog>
          ) : null}
        </div>
      </div>
    </div>
  );
}

type ErrorType = (MPGraphQLError | Error) & {
  additionalData?: {
    curatorFullName?: string;
    curatorUsername?: string;
  };
};

export default class ProtectedProductPage<T> extends PureComponent<
  T,
  { error: ErrorType; password: string }
> {
  static getDerivedStateFromError(error: ErrorType) {
    return { error };
  }

  static willCatch(error: ErrorType) {
    return error?.name === MpErrors.Locked;
  }

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);

    const savedPassword = sessionStorage.getItem(
      generateSessionStorageKey(document.location.pathname)
    );
    this.state = { error: null, password: savedPassword };
  }

  componentDidCatch(error: ErrorType) {
    if (!ProtectedProductPage.willCatch(error)) throw error;
  }

  handleSubmit(password: string) {
    sessionStorage.setItem(
      generateSessionStorageKey(document.location.pathname),
      password
    );
    this.setState({ password });
    this.resetErrorBoundary();
  }

  resetErrorBoundary() {
    this.setState({ error: null });
  }

  render() {
    if (ProtectedProductPage.willCatch(this.state.error)) {
      return (
        <PasswordPage
          curatorFullName={this.state.error?.additionalData?.curatorFullName}
          curatorUsername={this.state.error?.additionalData?.curatorUsername}
          password={this.state.password}
          onSubmit={this.handleSubmit}
        />
      );
    }

    return <ProductPage password={this.state.password} />;
  }
}
